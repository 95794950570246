import React, { useState, useEffect } from 'react';
import { auth } from '../firestore';
import { onAuthStateChanged, sendSignInLinkToEmail} from "firebase/auth";
import { useNavigate, useParams } from 'react-router-dom';
import './LoginPage.css'; // Import the CSS file for styling
import { useAppContext } from '../ContextProvider';
const LoginPage = () => {
  let { brandId } = useParams();
  //const domain = "@moymer.com"
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(`navigate: ${brandId}/main`);
        navigate(`/${brandId}/main`, {replace: true}); 
      }
    });

    // Clean up the subscription on component unmount
    return () => unsubscribe();
  }, [navigate,brandId]);


  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://falou.app/runservices/falou-branding-experience/brandDomain?brandId=${brandId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.text();
      setDomain(`@${result}`);
      dispatch({ type: 'SET_DOMAIN', payload: result });
    };

    fetchData();
   
  }, [dispatch,brandId]);


  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  const handleLogin = () => {
    const actionCodeSettings = {

      url: `https://moymer-demo.firebaseapp.com/${brandId}/finishLogin`, // Append continueUrl
      handleCodeInApp: true

    };
    const emailDomain = email + domain
    console.log(`handleLogin ${emailDomain}`);
    sendSignInLinkToEmail(auth, emailDomain, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', emailDomain);
        // ...
      })
      .catch((error) => {
        //const errorCode = error.code;
       // const errorMessage = error.message;
        console.error('Error sending email link', error);
      });
  };


  const isEmailFilled = email.trim() !== '';

  return (
    <div className="app-container">
      <header className="header"></header>
      <div className="login-container">
        <div className="login-box">
   
          <img src={`${process.env.PUBLIC_URL}/images/logo-login.png`}  alt="Logo" className="logo" />
          <div className="input-group">
            <input
              type="text"
              placeholder="Email"
              className="input-email"
              value={email}
              onChange={handleInputChange}
              required
            />
            <span>{domain}</span>
          </div>
          <LoginButton isEmailFilled={isEmailFilled} handleLogin={handleLogin} />
        </div>
      </div>
      <footer className="footer">
        <a href="/privacy">Privacy</a>
        <span className="divider">&#8226;</span>
        <a href="/terms">Terms</a>
      </footer>
    </div>
  );
};


const LoginButton = ({ isEmailFilled, handleLogin }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    console.log("handleClick");
    setIsLoading(true);
    handleLogin();
  };

  return (
    <button
      type="button"
      className={`login-button ${isEmailFilled ? 'active' : ''}`}
      disabled={!isEmailFilled || isLoading}
      onClick={handleClick}
    >
      {isLoading ? <div className="spinner"></div> : 'Login'}
    </button>
  );
};


export default LoginPage;
