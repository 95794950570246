import { auth } from '../firestore';
import {  signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import './FinishLoginPage.css'

const SignOutPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("..")
          }).catch((error) => {
            // An error happened.
          });
    }, [navigate]);

    return (
        <div class="centered-text">
           Sign out ...
       </div>
    );
};

export default SignOutPage;