import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LoginPage from './pages/LoginPage';
import AnalyticsPage from './pages/AnalyticsPage';
import FinishLoginPage from './pages/FinishLoginPage';
import SignOutPage from './pages/SignOutPage';
import { ContextProvider } from './ContextProvider';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/:brandId",
    children: [
      { path: "", 
        element: <LoginPage />,
       },
      { path: "main", 
        element: <AnalyticsPage />
       },
      {
        path: "finishLogin",
        element: <FinishLoginPage />
      },
      {
        path: "signOut",
        element: <SignOutPage />
      }
    ],
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

