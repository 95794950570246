import React, { useState, useEffect } from 'react';
import { useAppContext } from '../ContextProvider';
import './AnalyticsPage.css'; // Import the CSS file for styling
import { FaCalendarAlt, FaUserCircle } from 'react-icons/fa';
import { useParams, Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ labels, values, indexAxis }) => {
    var size = Math.min(labels.length, 10);

    var colors = [
        '#FFB27A',
        '#F8BC3C',
        '#3983F8',
        '#FF7557',
        '#7EE1D8',
        '#B1596E',
        '#72BEF4',
        '#FFB27A',
        '#0F7EA0',
        '#3BA974',
        '#FEBBB2'
    ].slice(0, size);
    const data = {
        labels: labels.slice(0, size),
        datasets: [
            {
                label: 'Exposed',
                data: values.slice(0, size),
                backgroundColor: colors.slice(0, size),
                borderWidth: 0,
                borderRadius: 3,
            },
        ],
    };
    const maxValue = Math.max(...data.datasets[0].data);
    const maxLimit = maxValue * 1.15; // Add 10% padding
    // Detect the user's browser locale
    const userLocale = navigator.language || 'en-US'; // Fallback to 'en-US' if not available

    // Define a formatter for the detected locale
    const numberFormatter = new Intl.NumberFormat(userLocale);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: indexAxis === 'x' ? true : false,
                max: maxLimit,
                grid: {
                    display: false, // Remove grid lines on the Y axis
                },
                ticks: {
                    display: indexAxis === 'x' ? false : true,
                },
            },
            x: {
                beginAtZero: indexAxis === 'x' ? false : true,
                max: maxLimit,
                grid: {
                    display: false, // Remove grid lines on the X axis
                },
                ticks: {
                    display: indexAxis === 'x' ? true : false, // Remove Y-axis values
                },
            }
        },
        indexAxis: indexAxis,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                anchor: 'end',
                align: 'start',
                formatter: Math.round,
                color: '#000',
                font: {
                    weight: 'bold'
                }
            }
        }
    };
    const plugins = [{
        afterDatasetsDraw: (chart) => {
            const { ctx, data } = chart;
            ctx.save();
            data.datasets.forEach((dataset, datasetIndex) => {
                chart.getDatasetMeta(datasetIndex).data.forEach((bar, index) => {
                    const value = dataset.data[index];
                    ctx.font = '12px Montserrat';
                    ctx.fillStyle = 'black';
                    ctx.textAlign = indexAxis === 'x' ? 'center' : 'left';
                    ctx.textBaseline = indexAxis === 'x' ? 'bottom' : 'middle';
                    ctx.fillText(numberFormatter.format(value), indexAxis === 'x' ? bar.x : bar.x + 5, indexAxis === 'x' ? bar.y - 5 : bar.y);
                });
            });
        }
    }];
    return <Bar data={data} options={options} plugins={plugins} />;
};

const getMonthAbbreviation = (index) => {
    const date = new Date();
    date.setMonth(index);
    return date.toLocaleString('default', { month: 'short' });
};
const getMonth = (index) => {
    const date = new Date();
    date.setMonth(index);
    return date.toLocaleString('default', { month: 'long' });
};

const months = Array.from({ length: 12 }, (_, i) => getMonth(i));
const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);

const AnalyticsPage = () => {


    const [genderData, setGenderData] = useState({});
    const [ageRangeData, setAgeRangeData] = useState({});
    const [locationData, setLocationData] = useState({});
    const [loading, setLoading] = useState(true);
    const { state } = useAppContext();
    const [menuVisible, setMenuVisible] = useState(false);
    let { brandId } = useParams();
    const [resultDate, setResultDate] = useState('');

    const [experienceCount, setExperienceCount] = useState('');

    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [isPickerVisible, setPickerVisible] = useState(false);

    const userLocale = navigator.language || 'en-US';
    const numberFormatter = new Intl.NumberFormat(userLocale);

    const togglePickerVisibility = () => {
        setPickerVisible(!isPickerVisible);
    };
    const confirmSelection = () => {
        formatAndApplyResultsDate(month, year);
        let monthRef = months.indexOf(month);
        let url = `https://falou.app/runservices/falou-branding-experience/stats?brandId=${brandId}&monthReference=${monthRef}&yearReference=${year}`;
        fetchData(url);
        setPickerVisible(false);
    };

    const formatAndApplyResultsDate = (month, year) => {
        let monthRef = months.indexOf(month);
        let monthMMM = getMonthAbbreviation(monthRef);
        let results = `${monthMMM} ${year} RESULTS`;
        setResultDate(results);
    };

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const fetchData = async (url) => {
        try {
            setLoading(true)
            console.log(`fetch: ${url}`)
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log(`result: ${result}`)
            setGenderData(result.totalByGender);
            setAgeRangeData(result.totalByAgeRange);
            setLocationData( transformLocationMap(result.totalByLocation));

            let total = (result.totalByGender["male"] || 0) + (result.totalByGender["female"] || 0) + (result.totalByGender["other"] || 0);
            setExperienceCount(total);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    function transformLocationMap(inputObj) {
        if(Object.keys(inputObj).length === 0 ) {
            return inputObj;
        }
        // Convert the object to an array of [key, value] pairs
        const entries = Object.entries(inputObj);
    
        // Sort the entries by value in descending order
        entries.sort((a, b) => b[1] - a[1]);
    
        // Select the top 9 entries
        const topEntries = entries.slice(0, 9);
    
        // Sum the remaining values
        const remainingSum = entries.slice(9).reduce((sum, entry) => sum + entry[1], 0);
    
        // Create a new object with the top 9 entries
        const resultObj = Object.fromEntries(topEntries);
    
        // Add the remaining sum to the object with a key like "others"
        resultObj.others = remainingSum;
    
        return resultObj;
    }

    useEffect(() => {
        setMonth(new Date().toLocaleString('default', { month: 'long' }));
        setYear(new Date().getFullYear());
        formatAndApplyResultsDate(new Date().toLocaleString('default', { month: 'long' }), new Date().getFullYear());

    }, []);

    useEffect(() => {
        let url = `https://falou.app/runservices/falou-branding-experience/stats?brandId=${brandId}&monthReference=${new Date().getMonth()}&yearReference=${new Date().getFullYear()}`;
        fetchData(url);
    }, [brandId]);


    return (
        <div className="app-container">
            <header className="header">
                <img src={`${process.env.PUBLIC_URL}/images/logo-main.png`} alt="Logo" className="logoMain" />
                <FaUserCircle className="profile-icon" onClick={toggleMenu} />
                {menuVisible && (
                    <div className="profile-menu">
                        <Link to="">Settings</Link>
                        <Link to="../SignOut">Logout</Link>
                    </div>
                )}
            </header>
            <div className="result-date-wrapper">
                <div className={`result-date-box ${isPickerVisible ? 'selected' : ''}`} onClick={togglePickerVisibility} >
                    <FaCalendarAlt className="calendar-icon" />
                    <span>{resultDate}</span>
                </div>

                {isPickerVisible && (
                    <div className="picker-container">
                        <select value={month} onChange={e => setMonth(e.target.value)} className="picker" >
                            {months.map((m, index) => (
                                <option key={index} value={m}>{m}</option>
                            ))}
                        </select>
                        <select value={year} onChange={e => setYear(e.target.value)} className="picker" style={{ width: '80px' }}>
                            {years.map((y, index) => (
                                <option key={index} value={y}>{y}</option>
                            ))}
                        </select>
                        <button onClick={confirmSelection} className="confirm-button">Confirm</button>
                    </div>
                )}
            </div>

            <div className="results-container">

                <div className="result-box" key="item1">
                    <div className="result-box-header">Falou Branding Experiences</div>
                    <div class="experience-container">
                        <div class="experience-text">
                            FALOU EXPERIENCES<span class="info-icon">i</span>
                        </div>
                        <div class="experience-value">
                            {loading ? '' : numberFormatter.format(experienceCount)}
                        </div>
                    </div>
                </div>

                <div className="result-box" key="item2">
                    <div className="result-box-header">Countries</div>
                    <div className="result-box-content">
                        {
                            loading ?
                                (<div className='no-data-info'>LOADING...</div>) :
                                (
                                    (Object.keys(locationData).length === 0) ? (
                                        <div className='no-data-info'>NO DATA</div>
                                    ) : (
                                        <BarChart labels={Object.keys(locationData)} values={Object.values(locationData)} indexAxis={'y'} />
                                    )
                                )
                        }

                    </div>

                </div>

                <div className="result-box" key="item3">
                    <div className="result-box-header">Age <span class="info-icon">i</span></div>
                    <div className="result-box-content">
                        {
                            loading ?
                                (<div className='no-data-info'>LOADING...</div>) :
                                (
                                    (Object.keys(ageRangeData).length === 0) ? (
                                        <div className='no-data-info'>NO DATA</div>
                                    ) : (
                                        <BarChart labels={Object.keys(ageRangeData)} values={Object.values(ageRangeData)} indexAxis={'x'} />
                                    )
                                )
                        }
                    </div>
                </div>
                <div className="result-box" key="item3">
                    <div className="result-box-header">Gender</div>
                    <div className="result-box-content">
                        {
                            loading ?
                                (<div className='no-data-info'>LOADING...</div>) :
                                (
                                    (Object.keys(genderData).length === 0) ? (
                                        <div className='no-data-info'>NO DATA</div>
                                    ) : (
                                        <BarChart labels={Object.keys(genderData)} values={Object.values(genderData)} indexAxis={'x'} />
                                    )
                                )
                        }

                    </div>
                </div>
            </div>

        </div>
    );
};

export default AnalyticsPage;
