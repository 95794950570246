import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

  const firebaseConfig = {
    apiKey: "AIzaSyAKJZoVs2Net3hoM0O9wv4uLTdjA_LtICg",
    authDomain: "moymer-demo.firebaseapp.com",
    databaseURL: "https://moymer-demo.firebaseio.com",
    projectId: "moymer-demo",
    storageBucket: "moymer-demo.appspot.com",
    messagingSenderId: "1022919472232",
    appId: "1:1022919472232:web:48c0c2e285abc14fbf77dd",
    measurementId: "G-7SYSP9J9D7"
  };
const app = initializeApp(firebaseConfig);

// Get a Firestore instance with the specified database URL
const db = getFirestore(app, "brandingexperience");

// Initialize Auth
const auth = getAuth(app);

export { db, auth };